
    export function fetcher<TData, TVariables>(query: string, variables?: TVariables) {
      const map = {
   "4b354f0b6a57dd6c2fbdd9a2bc93b0791369dfd45e50c0bdb29153a7c5a47cbd": "query ContentFirstLevelPageBreadcrumbs($remoteId: String!) {\n  loadContentFirstLevelPage(id: $remoteId) {\n    breadcrumbs {\n      text: title\n      url\n      __typename\n    }\n    __typename\n  }\n}",
   "097641b1d56120c7c90995b76d8b83d2e1b392e4bf0bc7150d7ae94b0b1371ef": "query ContentSecondLevelPageBreadcrumbs($remoteId: String!) {\n  loadContentSecondLevelPage(id: $remoteId) {\n    breadcrumbs {\n      text: title\n      url\n      __typename\n    }\n    __typename\n  }\n}",
   "253bac63919e8274bd870f6108283bf789a665c184a3c8a40537e3f3f69c19bd": "query ContentThirdLevelPageBreadcrumbs($remoteId: String!) {\n  loadContentThirdLevelPage(id: $remoteId) {\n    breadcrumbs {\n      text: title\n      url\n      __typename\n    }\n    __typename\n  }\n}",
   "6cf6b8a067fe871a3735ea56bdec1936455be08e44ae3d281e7541324dc5294e": "query ListingCompanyParaworkSearch($offset: Int!, $limit: Int!, $language: String, $text: String, $glossary: String, $industry: [String!], $jobType: [String!]) {\n  listingCompanyParaworkSearch(pagination: {offset: $offset, limit: $limit}, filters: {language: $language, text: $text, glossary: $glossary, industry: $industry, paraworkJobType: $jobType}) {\n    total\n    items {\n      drupalId\n      path\n      title\n      email\n      place\n      details\n      zip\n      streetAndNumber\n      telephone\n      paraworkJobType {\n        title\n        id\n        __typename\n      }\n      paraworkJobCompany\n      hasContent\n      websiteLink {\n        title\n        url\n        __typename\n      }\n      logo {\n        styles {\n          id\n          url\n          __typename\n        }\n        __typename\n      }\n      __typename\n    }\n    __typename\n  }\n}",
   "9bbcf2b0b4d3af37c2ff44fdeb01c36b8e094af0dedb69e4fa5cf013ae69311d": "query ListingCompanySearch($offset: Int!, $limit: Int!, $language: String, $text: String, $glossary: String, $industry: [String!]) {\n  listingCompanySearch(pagination: {offset: $offset, limit: $limit}, filters: {language: $language, text: $text, glossary: $glossary, industry: $industry}) {\n    total\n    items {\n      drupalId\n      path\n      title\n      email\n      place\n      details\n      zip\n      streetAndNumber\n      telephone\n      paraworkJobType {\n        title\n        id\n        __typename\n      }\n      paraworkJobCompany\n      hasContent\n      websiteLink {\n        title\n        url\n        __typename\n      }\n      logo {\n        styles {\n          id\n          url\n          __typename\n        }\n        __typename\n      }\n      __typename\n    }\n    __typename\n  }\n}",
   "788f74a473c5b6edf4fb519513c90bc04eac8c479f576a5a53a55eb26733228d": "query ListingDoctorSearch($offset: Int!, $limit: Int!, $language: String, $glossary: String, $field: [String!], $doctorFunction: [String!]) {\n  listingDoctorSearch(pagination: {offset: $offset, limit: $limit}, filters: {language: $language, glossary: $glossary, field: $field, doctorFunction: $doctorFunction}) {\n    total\n    items {\n      drupalId\n      path\n      title\n      domainAccess {\n        id\n        __typename\n      }\n      ... on ContentDoctor {\n        path\n        firstName\n        lastName\n        job\n        additionalInformation\n        specialistDepartment\n        specialistTeaserTitle\n        additionalFields {\n          title\n          id\n          __typename\n        }\n        doctorFunction {\n          title\n          id\n          __typename\n        }\n        mainField {\n          title\n          id\n          __typename\n        }\n        email\n        telephone\n        fax\n        teaserImage {\n          ... on MediaImage {\n            __typename\n            url\n            styles {\n              id\n              url\n              width\n              height\n              __typename\n            }\n            width\n            height\n            alt\n            title\n            mimeType\n          }\n          __typename\n        }\n        __typename\n      }\n      __typename\n    }\n    __typename\n  }\n}",
   "87083e60490ccd984f1c34d477f7bfeb3492df89a9c89b2c359a664ae78d7e73": "query ListingMedicalOfferSearch($offset: Int!, $limit: Int!, $language: String, $glossary: String, $medicalDomain: [String!]) {\n  listingMedicalOfferSearch(pagination: {offset: $offset, limit: $limit}, filters: {language: $language, glossary: $glossary, medicalDomain: $medicalDomain}) {\n    total\n    items {\n      drupalId\n      path\n      title\n      domainAccess {\n        id\n        __typename\n      }\n      __typename\n    }\n    __typename\n  }\n}",
   "ea815d201b5a07e0592e8cfff513fb5e634ae39450153aa8606941ed4a5813ac": "query ListingNewsroom($offset: Int!, $limit: Int!, $newsType: [String!]!, $organisation: String, $highlightedCount: Int, $language: String) {\n  listingNewsroom(pagination: {offset: $offset, limit: $limit}, filters: {organisation: $organisation, language: $language, news_type: $newsType}, extras: {highlightedCount: $highlightedCount}) {\n    total\n    items {\n      __typename\n      ... on NodeInterface {\n        path\n        __typename\n      }\n      ... on ContentNews {\n        title\n        date\n        langcode\n        sticky\n        newsType {\n          title\n          __typename\n        }\n        domainAccess {\n          id\n          __typename\n        }\n        teaserImage {\n          __typename\n          url\n          styles {\n            id\n            url\n            width\n            height\n            __typename\n          }\n          width\n          height\n          alt\n          title\n          mimeType\n        }\n        __typename\n      }\n      ... on ContentBlog {\n        title\n        langcode\n        created\n        sticky\n        newsType {\n          title\n          __typename\n        }\n        domainAccess {\n          id\n          __typename\n        }\n        teaserImage {\n          __typename\n          url\n          styles {\n            id\n            url\n            width\n            height\n            __typename\n          }\n          width\n          height\n          alt\n          title\n          mimeType\n        }\n        __typename\n      }\n    }\n    __typename\n  }\n}"
};
      const match = query.match(/((query)|(mutation)) [A-Za-z0-9_]+[ {(]/);
      if (!match) {
        throw new Error(`Cannot find query name. Query: ${query}`);
      }
      const name = match[0];
      const entry = Object.entries(map).find(([_, query]) => query.includes(name));
      if (!entry) {
        throw new Error(`Cannot find query ID. Query name: ${name}. Query map: ${JSON.stringify(map)}`);
      }
      const id = entry[0];
      return async (): Promise<TData> => {
        const res = await fetch(process.env.GATSBY_GRAPHQL_ENDPOINT as string, {
        method: "POST",
    ...({"credentials":"include","headers":{"Content-Type":"application/json"}}),
          body: JSON.stringify({ id, variables }),
        });
        const json = await res.json();
        if (json.errors) {
          const { message } = json.errors[0];
          throw new Error(message);
        }
        return json.data;
      }
    }
  